import React from "react";
import phone from "./social_network_icons/phone.png";
import email from "./social_network_icons/email.png";
import github from "./social_network_icons/github.png";
import linkedin from "./social_network_icons/linkedin.png";

function Contact () {
    return (
        <div className="row justify-content-center text-center" id="contact">
            <h1 className="col-12 mt-3 mb-5">Contact Me</h1>
            <div className="contactDiv justify-content-center">
                <div className="d-flex align-items-center">
                    <img className="colour contactIcons mr-5" alt="photo" src={phone}/>
                    <a href="tel:+37062791692" className="font">+370 627 91692</a>
                </div>
                <div className="d-flex align-items-center">
                    <img className="colour contactIcons mr-5" alt="photo" src={email}/>
                    <a href="mailto:vaida.dabasinskaite@gmail.com" className="font">vaida.dabasinskaite@gmail.com</a>
                </div>
                <div className="d-flex align-items-center">
                    <img className="colour contactIcons mr-5" alt="photo" src={linkedin}/>
                    <a className="font" href="https://www.linkedin.com/in/vaida-%C5%A1uop%C4%97-47b09518a/"
                       target="_blank">Let's meet on LinkedIn!</a>
                </div>
                <div className="d-flex align-items-center">
                    <img className="colour contactIcons mr-5" alt="photo" src={github}/>
                    <a className="font" href="https://github.com/vaidasuope" target="_blank">Find me on GitHub!</a>
                </div>
            </div>
        </div>
    );
}
export default Contact;