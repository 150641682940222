const schools = [
    {
        name: "Kaunas School of Information Technologies",
        profession: "Web developer",
        year: "2020 - 2021"
    },
    {
        name: "Kaunas University of Technology",
        profession: "Advertising technologies and marketing",
        year: "2006 - 2010"
    },
    {
        name: "Kriūkai Secondary School",
        profession: "Secondary school diploma with honors",
        year: "1994 - 2006"
    },
]
export default schools;