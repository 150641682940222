const experience = [
    {
        company:"Festo, UAB",
        year: "6/2019 - now",
        position: "Onsite Search Specialist (SEO Analyst)",
        description: "Responsible for updating insights and dashboards for onsite search. Set-up, continuously improvement and optimization of onsite search through bug fixing of missing and not best allocated articles as well as 0-results list.  Responsible for creating new operational processes and improvements to ensure data collection and reporting accuracy, including documenting the new procedures. Communication and cooperation with Cluster Marketing Responsible to continuously improve onsite search experience. Supporting the colleagues of global online business development with the analysis and insights."
    },
    {
        company:"Festo, UAB",
        year: "6/2014 - 6/2019",
        position: "Senior Sales Support Specialist",
        description: "Responsible for the front and back office tasks. Front office tasks – handling of the orders and quotations with the special conditions in SAP. Back office tasks – handling of the credit notes and the ICM’s in SAP. Also monitoring of the customer master data and changing according the requests of the customers. Communicating with the local agents and solving the problems."
    },
    {
        company:"Festo, UAB",
        year: "05/2013 - 6/2014",
        position: "Sales Support Specialist",
        description: "Supporting the local companies with the processing the orders and quotations according the standard rules."
    }
]
export default experience;