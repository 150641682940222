const skills = [
    {
        id: "HTML5"
    },
    {
        id: "CSS"
    },
    {
        id: "JavaScript"
    },
    {
        id: "Bootstrap"
    },
    {
        id: "React"
    },
    {
        id: "GitHub"
    },
    {
        id: "Java"
    },
    {
        id: "C++"
    }

]
export default skills;