import React,{useState} from "react";
import "../App.css";
import {Link} from "react-router-dom";
import home from "./photos/home.png";
import cv from "./photos/cv.png";
import portfolio from "./photos/portfolio.png";
import contact from "./photos/contact.png";


function Menu () {

    const [active,setActive]=useState({
        activeObject:null,
        object:[
            {img: [home],
                tag: "/"},
            {img: [cv],
                tag: "/cv"},
            {img:[portfolio],
                tag: "/portfolio"},
            {img: [contact],
                tag: "/contact"}]
    });

    function toggleActive(index){
        setActive({ ...active, activeObject: active.object[index]});
    }

    function toggleActiveStyles(index){
        if(active.object[index] === active.activeObject){
            return "scale";
        }else {
            return null;
        }
    }

    return (
        <div id="sideMenu" className="col-md sticky-top d-flex justify-content-center align-items-center">
            {active.object.map((elements,index) => (
                <div key={index}
                     className={toggleActiveStyles(index)}
                     onClick={()=>{toggleActive(index)}}>
                    <Link to={elements.tag}><p><img className="menuPhoto" src={elements.img}/></p></Link>
                </div>
            ))}
        </div>
    )
}
export default Menu;