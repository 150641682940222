import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "../App.css";
import github from "./social_network_icons/github.png";
import demo from "./social_network_icons/demo.png"
import portfolio from "./data/Projects";


function Portfolio() {

    return (
        <div className="row justify-content-center" id="portfolio">
            <h1 className="col-12 mt-3 mb-5 text-center">Portfolio</h1>
                    {portfolio.map((work, index) => (
                    <div key={index} className="col-md-5 col-12 text-center">
                        <h3>{work.title}</h3>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={work.img1}
                                    alt={work.title}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={work.img2}
                                    alt={work.title}
                                />
                            </Carousel.Item>
                        </Carousel>
                            <p>{work.description}</p>
                        <a href={work.github} target="_blank">
                            <img className="socialPhoto" src={github} alt="GitHub"/>
                        </a>
                        <a className="ml-3" href={work.link} target="_blank">
                            <img className="socialPhoto" src={demo} alt="GitHub"/>
                        </a>
                        <hr/>
                    </div>
                    ))}
        </div>
    )

}

export default Portfolio;