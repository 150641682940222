import weather from "../carousel_photos/weather.PNG";
import weather1 from "../carousel_photos/weather1.PNG";
import bookShop from "../carousel_photos/bookShop.PNG";
import bookShop1 from "../carousel_photos/bookShop1.PNG";
import movie from "../carousel_photos/movie.PNG";
import movie1 from "../carousel_photos/movie1.PNG";
import todo from "../carousel_photos/todo.PNG";
import todo1 from "../carousel_photos/todo1.PNG";

const portfolio = [
    {
        title: "Weather App",
        description: "Data from weather API. By default Kaunas city is loaded. It is search bar and there is a possibility to find the weather of any city of Lithuania. Today's weather is shown and the forecast of the next 6 days.",
        img1: [weather],
        img2: [weather1],
        github: "https://github.com/vaidasuope/JS_Weather_APP",
        link: "https://vaidasuope.github.io/JS_Weather_APP/."
    },
    {
        title: "Shopping cart",
        description: "Shopping cart with React. The functionality added to the project: add items one by one to cart, delete item all together, one by one. Clear all cart. There is no possibility to buy more items then are in the warehouse.",
        img1: [bookShop],
        img2: [bookShop1],
        github: "https://github.com/vaidasuope/React_BookShop",
        link: "https://bookshop-7zdrzxh35.vercel.app/"
    },
    {
        title: "Movie Search App",
        description: "Search option to find the all possible movies containing the search word. Learn more button to read more information.",
        img1: [movie],
        img2: [movie1],
        github: "https://github.com/vaidasuope/React_MovieSearch",
        link: "https://movie-search-mu.vercel.app/"
    },
    {
        title: "Todo List App",
        description: "Todo list App - there is a possibility to add new entry, to check it, to delete it. When is checked - the entry is crossed over.",
        img1: [todo],
        img2: [todo1],
        github: "https://github.com/vaidasuope/ToDo_List_JavaScript",
        link: "https://vaidasuope.github.io/ToDo_List_JavaScript/"
    }
]
export default portfolio;