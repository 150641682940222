import React from 'react';
import Typist from "react-typist";
import linkedin from "./social_network_icons/linkedin.png";
import github from "./social_network_icons/github.png";
import facebook from "./social_network_icons/facebook.png";
import {useHistory} from "react-router";

function Home (){

        let history = useHistory();
        function handleClick() {
            history.push("/contact");
        }

    return (
        <div className="row justify-content-center" id="home">
            <div className="col-xl-8 col">
                <Typist cursor={{show:false}}>
                    <Typist.Delay ms={800}/>
                    <span>&lt;body&gt;</span>
                    </Typist>
                <div className="name">
                    <Typist cursor={{show:false}}>
                        <Typist.Delay ms={1000}/>
                        <span>&lt;h1&gt;</span>
                        <h1>Hey, <br/>
                            I'm Vaida <br/>
                            Junior Web Developer.
                        </h1>
                        <span>&lt;/h1&gt;</span>
                    </Typist>
                    <Typist cursor={{show:false}}>
                        <Typist.Delay ms={4600}/>
                    <div className="col-9 mt-5">
                    <button className="float-right btn" id="button" type="button" onClick={handleClick}>Contact Me!</button>
                </div>
                    </Typist>
                </div>
                <Typist cursor={{show:false}}>
                    <Typist.Delay ms={5000}/>
                    <span>&lt;/body&gt;</span>
                </Typist>
            </div>
            <div className="col-xl d-sm-none d-xs-none d-md-none d-none d-lg-block">
                <figure className="circle mt-5">
                    <span className="linkedin">
                        <a href="https://www.linkedin.com/in/vaida-%C5%A1uop%C4%97-47b09518a/" target="_blank">
                        <img className="phCircle" src={linkedin}/>
                        </a>
                    </span>
                    <span className="github">
                        <a href="https://github.com/vaidasuope" target="_blank">
                        <img className="phCircle" src={github}/>
                        </a>
                    </span>
                    <span className="facebook">
                        <a href="https://www.facebook.com/vaida.dabasinskaite.3" target="_blank">
                        <img className="phCircle" src={facebook}/>
                        </a>
                    </span>
                </figure>
            </div>
        </div>
    )
}
export default Home;
