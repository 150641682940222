import React from 'react';
import "../App.css";
import experience from "./data/Jobs";
import schools from "./data/Schools";
import skills from "./data/Skills";
import {useHistory} from "react-router";

function Resume() {

    let history = useHistory();
    function handleClick() {
        history.push("/contact");
    }

    return (
        <div className="row justify-content-center" id="cv">
            <div className="col-12 text-center mt-3">
                <h1>Resume</h1>
            </div>
            <h3 className="col-11 text-center" id="jobList">Experience</h3>
            {experience.map((element, index) => (
                <div key={index} className="col-10 text-justify">
                    <h4 className="text-center">{element.company}</h4>
                    <h5 className="text-center">{element.year}</h5>
                    <h5 className="text-center">{element.position}</h5>
                    <p>{element.description}</p>
                    <hr/>
                </div>
            ))}
            <h3 className="col-11 text-center" id="jobList">Education</h3>
            {schools.map((school, index) => (
                <div key={index} id="schools" className="text-center col-md-5 col-10">
                    <h4>{school.name}</h4>
                    <h6>{school.year}</h6>
                    <p >{school.profession}</p>
                    <hr/>
                </div>
            ))}
            <h3 className="col-12 text-center" id="jobList">Skills</h3>
            <div className="row mb-5 justify-content-center">
            {skills.map((skill, index) => (
                    <p key={index} className="d-flex justify-content-center align-items-center rounded-pill m-3" id="pill">{skill.id}</p>
            ))}
            </div>
            <div className="col-12 d-flex justify-content-center mb-5">
                <button className="float-right btn btn-secondary" type="button" onClick={handleClick}>Contact Me!</button>
            </div>
        </div>
    )
}

export default Resume;