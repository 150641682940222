import React, {useState} from "react";
import "../App.css";
import Portfolio from "./Portfolio";
import Home from "./Home";
import Resume from "./Resume";
import Contact from "./Contact";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Menu from "./Menu";

function Main() {

    return (
        <div className="">
            <Router>
                <div className="row" id="main">
                    <Menu/>
                    <div className="col-md" id="content">
                        <Switch>
                            <Route path="/cv">
                                <Resume/>
                            </Route>
                            <Route path="/portfolio">
                                <Portfolio/>
                            </Route>
                            <Route path="/contact">
                                <Contact/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router>
        </div>
    )
}

export default Main;